<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Wildcard SSL-certificaat</h1>
        <Price :item="data.staticData.prices.SSLCertificatesPrices[2]"/>
        <h2>Wildcard SSL certificaat</h2>
        <p>Het wildcard SSL certificaat beveiligt een onbeperkt aantal subdomeinen binnen één domein, zodat u daar als grote (online) organisatie gebruik van kunt maken. Het certificaat wordt verbonden aan één domein, waarbinnen u een onbeperkt aantal subdomeinen kunt beveiligen.</p>
        <p><i>Let op: met een wildcard SSL certificaat kunt u één subniveau beveiligen. U beveiligt dus *.uwdomein.nl, maar niet *.*.uwdomein.nl of diepere lagen in de website.</i></p>
        <p>Om een wildcard SSL certificaat aan te kunnen vragen heeft u een CSR nodig. De levertijd hiervan is afhankelijk van het type validatie dat u wenst, want er bestaan daarvoor twee verschillende mogelijkheden. U kunt de wildcard SSL aanvragen met domein validatie (DV) en daar binnen een paar minuten gebruik van maken. De aanvraag van een wildcard SSL certificaat met organisatie validatie (OV) zal maximaal enkele dagen duren.</p>
        <h3>Wildcard SSL met certificaat</h3>
        <p>In de beide gevallen is er sprake van wildcard SSL met een certificaat, waardoor u de mogelijkheid heeft om een compleet domein inclusief alle subdomeinen te beveiligen. Dat is prettig wanneer u veel subdomeinen heeft, omdat de kosten van meerdere multi-domein SSL certificaten dan gemakkelijk op zouden lopen.</p>
        <p>Bijkomend voordeel is dat u niet steeds een nieuw SSL certificaat of een uitbreiding daarvan hoeft aan te vragen op het moment dat er een subdomein bijkomt of er een subdomein verandert. In dat geval geldt de onbeperkte geldigheid van de wildcard SSL voor het complete domein, waardoor ook het nieuwe subdomein daar automatisch onder valt.</p>
        <p>Het SSL certificaat op basis van een wildcard zorgt voor een 100% beveiligde online verbinding, zodat bezoekers zonder zorgen vertrouwelijke gegevens naar u kunnen versturen. Bovendien zorgt u met het certificaat voor het bekende slotje in de adresbalk, waardoor bezoekers kunnen nagaan dat de website die ze bezoeken de website is die ze daadwerkelijk wilden bezoeken. <i>Let op: er is met een wildcard SSL niet standaard sprake van een groene adresbalk, daarvoor kunt u gebruik maken van de uitgebreide EV-validatie.</i></p>
        <h3>Wildcard certificate vergelijken</h3>
        <p>Het is de moeite waard om een wildcard certificate te vergelijken met bijvoorbeeld de multi-domein SSL certificaten die er beschikbaar zijn, net als met de specifieke EV-certificaten voor de meest uitgebreide validatie. We zorgen er daarmee voor dat we onderscheid kunnen maken voor verschillende organisaties, afhankelijk van hoe u er gebruik van wilt maken.</p>
        <p>We hebben veel ervaring met SSL certificaten en het gebruik daarvan, waardoor we u op basis van uw specifieke situatie goed kunnen aangeven at de beste keuze is. We adviseren u een SSL certificaat dat aansluit bij de structuur van uw (online) organisatie, zodat u voor zowel nu als in de toekomst de juiste keuze kunt maken.</p>
        <h3>Voordelen van een SSL certificaat bij Hosting on Demand</h3>
        <p>Bij Hosting on Demand profiteert u met een wildcard SSL certificaat van:</p>
        <ul>
          <li>Een 100% beveiligde verbinding</li>
          <li>Snelle levering van ieder SSL certificaat</li>
          <li>Betere vindbaarheid in Google</li>
        </ul>
        <p>Een wildcard SSL certificaat is standaard 1 jaar geldig. Uiteraard heeft u de mogelijkheid om de geldigheid daarna te verlengen, zodat u er gedurende een langere periode gebruik van kunt maken. Op die manier beveiligt u eenvoudig een compleet domein, inclusief alle subdomeinen waarvan sprake is.</p>
        <RandomQuote/>
      </div>
      <SSLCertificatesRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Price from '@/components/Price.vue';
import SSLCertificatesRightSideBlocks from '@/components/SSLCertificatesRightSideBlocks.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'WildcardSSLCertificaat',
  components: {
    Breadcrumbs,
    Price,
    SSLCertificatesRightSideBlocks,
    RandomQuote,
  },
  setup() {
    const data = inject('data');

    return {
      // store
      data,
    };
  },
}
</script>